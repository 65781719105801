import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";

import MenuAppBar from "../app-bar";

import { config } from "../../config";

const URL = config.url;

function Cookbook() {
  const [cookbook, setCookbook] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCookbook = async () => {
      const res = await axios.get(`${URL}/recipes`, { withCredentials: true });
      if (res.status === 200) {
        if (res.data === "Not Authorized") {
          navigate("/");
        } else {
          setCookbook(res.data);
        }
      } else {
        setCookbook([]);
      }
    };

    fetchCookbook();
  }, []);

  return (
    <div className="app">
      <MenuAppBar />
      <div>
        <h1>Recipes</h1>
        <ul>{
          cookbook.map((recipe) =>
            <li key={recipe.id}>
              {recipe.text}
            </li>
          )
        }
        </ul>
      </div>
    </div>
  );
}

export default Cookbook;

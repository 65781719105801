import React from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { useAuthStore } from "./auth-store";
import { config } from "../../config";

const URL = config.url;

function GoogleLoginButton() {
  const [cookies, setCookie] = useCookies(["user"]);
  const profile = useAuthStore((state) => state.profile);
  const setProfile = useAuthStore((state) => state.setProfile);
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (code) => {
      const user = await axios.post(`${URL}/auth/google`, {
        code
      });

      setProfile(user.data);
      setCookie("user", user.data);
      navigate("/cookbook");
    },
    flow: "auth-code",
    onError: (error) => console.log("Login Failed:", error),
  });

  const signOut = async () => {
    googleLogout();
    const response = await axios(`${URL}/auth/logout`, { withCredentials: true });
    if (response.status === 200) {
      setProfile(null);
      navigate("/");
    }
  };

  if (profile == null && cookies.user) {
    setProfile(cookies.user);
  }

  return (
    <div>
      {profile || cookies["user"] ? (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => signOut()}
        >
          Log out
        </Button>
      ) : (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<GoogleIcon />}
          onClick={() => login()}
        >
          Sign in with Google
        </Button>
      )}
    </div>
  );
}

export default GoogleLoginButton;

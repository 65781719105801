import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import { useAuthStore } from "./auth/auth-store";
import GoogleLoginButton from "./auth/google-login-button";

function MenuAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profile = useAuthStore((state) => state.profile);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            startIcon={<Avatar src="/icon-512x512.png" />}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to={"/"}>Sous Chef</Link>
          </Typography>
          {profile && (
            <div>
              <Button
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                startIcon={<Avatar src={profile.picture} />}
              />
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <Link to={"/profile"}>Profile</Link>
                </MenuItem>
                <MenuItem>
                  <Link to={"/cookbook"}>Cookbook</Link>
                </MenuItem>
                <MenuItem>
                  <GoogleLoginButton />
                </MenuItem>
              </Menu>
            </div>
          )}

          {!profile && <GoogleLoginButton />}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default MenuAppBar;

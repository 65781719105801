import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Container";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="https://images.unsplash.com/photo-1588960952097-4cf35f0a0306"
                alt="cookbook"
                sx={{ height: 200 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Your Cookbook
              </Typography>
              <Typography variant="h5">
                Your recipes, your way. We help you keep track of your family's
                recipes.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/grocery-list.jpg"
                alt="list"
                sx={{ height: 200 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                We make the grocery list
              </Typography>
              <Typography variant="h5">
                Choose what you want to cook this week and we'll prepare the
                grocery list for you!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/food.jpg"
                alt="food"
                sx={{ height: 200 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                We remix your recipes
              </Typography>
              <Typography variant="h5">
                Let us help you choose what to cook for breakfast, lunch, and
                dinner every day of every week!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;

import React from "react";
import { createBrowserRouter } from "react-router-dom";

import LandingPage from "./features/landing/landing-page.js";
import Cookbook from "./features/cookbook/cookbook.js";
import Profile from "./features/auth/profile.js";

export const Router = createBrowserRouter([
  { path: "/", element: <LandingPage /> },
  { path: "profile", element: <Profile /> },
  { path: "cookbook", element: <Cookbook /> },
]);

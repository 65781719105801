import React from "react";
import { googleLogout } from "@react-oauth/google";
import { Grid } from "@mui/material";

import { useAuthStore } from "./auth-store";
import GoogleLoginButton from "./google-login-button";
import MenuAppBar from "../app-bar";

const Profile = () => {
  const profile = useAuthStore((state) => state.profile);
  const setProfile = useAuthStore((state) => state.setProfile);

  const signOut = () => {
    googleLogout();
    setProfile(null);
  };

  return (
    <div>
      <MenuAppBar />
      <Grid
        container
        spacing={2}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <h1>Profile</h1>
        </Grid>
        {profile ? (
          <>
            <Grid item>
              <img src={profile.picture} alt="User's profile." />
            </Grid>
            <Grid item>{profile.name}</Grid>
            <Grid item>{profile.email}</Grid>
            <Grid item>
              <button onClick={signOut}>Log out</button>
            </Grid>
          </>
        ) : (
          <Grid item>
            <GoogleLoginButton />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Profile;

// id: 'aNumber',
// email: 'test@gmail.com',
// given_name: 'First',
// family_name: 'Name',
// picture: 'anHttpsLink',

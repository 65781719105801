import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import { Router } from "./router.js";
import { CookiesProvider } from "react-cookie";

import * as serviceWorkerRegistration from "./pwa/serviceWorkerRegistration.js";
import reportWebVitals from "./pwa/reportWebVitals.js";
import Theme from "./theme.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <GoogleOAuthProvider clientId="930285465683-58t73t1gtjmj9q8vlgbjhi3rf6e3ud30.apps.googleusercontent.com">
        <ThemeProvider theme={Theme}>
          <CssBaseline enableColorScheme />
          <RouterProvider router={Router} />
        </ThemeProvider>
      </GoogleOAuthProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

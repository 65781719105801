import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const Theme = createTheme({
  palette: {
    primary: {
      main: "#F3EFF5",
    },
    secondary: {
      main: "#7CE577",
    },
    error: {
      main: "#FF5E5B",
    },
    warning: {
      main: "#E3B23C",
    },
    info: {
      main: "#4D5061",
    },
  },
});

export default Theme;

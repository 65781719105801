import React from "react";

import MenuAppBar from "../app-bar";
import ProductHero from "./product-hero";
import ProductValues from "./product-values";
import ProductCategories from "./product-categories";
import ProductHowItWorks from "./product-how-it-works";

function LandingPage() {
  return (
    <div className="app">
      <MenuAppBar />
      <ProductHero />
      <ProductValues />
      <ProductCategories />
      <ProductHowItWorks />
      {/* <ProductCTA />
      <ProductSmokingHero />
      <AppFooter /> */}
    </div>
  );
}

export default LandingPage;
